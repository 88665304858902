export const trackingEvents = {
    computed: {
        trackingEvents() {
            return this.$store.getters["conversions/tracking_events"];
        },
    },
    methods: {
        getTrackingEvent(date, campaign_id) {
            let dt = new Date(date);
            let date_string = dt.toISOString().split("T")[0];

            return this.trackingEvents.filter((event) => {
                let campaign_match = true;
                if (campaign_id !== "") {
                    campaign_match =
                        event.campaign_id == campaign_id || event.campaign_id === null;
                }
                return event.date === date_string && campaign_match;
            });
        },
        hasTrackingEvent(date, campaign_id) {
            return this.getTrackingEvent(date, campaign_id).length !== 0;
        },
        trackingEventLabel(date, campaign_id) {
            let events = this.getTrackingEvent(date, campaign_id).map((event) => {
                return event.label;
            });
            return events.join(" | ");
        },
    }
}