<template>
  <div class="simple-metric" :class="{ 'simple-metric--list': list }">
    <div class="simple-metric__label">{{ label }}</div>
    <div
      class="simple-metric__measure"
      :class="{ 'simple-metric__measure--alert': alert }"
    >
      {{ measure }}
      <pill
        v-if="context && !list"
        :count="context"
        :label="true"
        :alert="alert"
      />
      <div class="simple-metric__icon" v-if="icon">
        <BaseSVG :src="require(`@/assets/${icon}.svg`)" />
      </div>
    </div>
    <div class="simple-metric__context" v-if="context && list">
      {{ context }}
    </div>
  </div>
</template>

<script>
import Pill from "@/components/Pill";
import BaseSVG from "@/components/base/BaseSVG";

export default {
  name: "SimpleMetric",
  props: {
    label: {
      type: String,
      required: true,
      default: "",
    },
    measure: {
      type: String,
      required: true,
      default: "",
    },
    context: {
      type: String,
      required: false,
    },
    alert: {
      type: Boolean,
      required: false,
    },
    list: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: false,
    },
  },
  components: {
    Pill,
    BaseSVG,
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";

.simple-metric {
  &:not(.simple-metric--list) {
    padding: 1rem;
    border-right: solid 1px #e9e9e9;
  }

  &__label {
    display: block;
    margin-bottom: 0.375rem;
    font-size: 0.75rem;
    color: $medium-gray;
  }

  &__measure {
    display: flex;
    align-items: center;

    &:not(.pill) {
      font-size: 2rem;
      font-weight: 600;
      color: $dark-blue;
    }

    &--alert {
      color: $red !important;
    }

    .pill {
      margin-left: auto;
    }
  }

  &:last-of-type {
    border-right: none;
  }

  &__icon {
    width: 24px;
    height: 24px;
    background: $fade-green;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;

    svg {
      width: 16px;
      height: 16px;
      fill: $green;
    }

    &.alert {
      background: $fade-red;

      svg {
        fill: $red;
      }
    }
  }

  &--list {
    align-items: center;
    border-bottom: 1px solid $fade-gray;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    padding: 0 0 0.5rem 0;

    &:last-of-type {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .simple-metric__label {
      flex-grow: 1;
      font-size: 12px;
      margin-bottom: 0;
    }

    .simple-metric__measure {
      font-size: 20px;
      font-weight: 600;
      justify-content: right;
      width: 75px;
      color: $dark-blue;

      &--alert {
        color: $red;
      }
    }

    .simple-metric__context {
      color: $medium-gray;
      font-size: 12px;
      text-align: right;
      width: 60px;
    }
  }
}
</style>