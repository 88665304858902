<template>
	<div class="circle-chart">
		<div class="circle-chart-metric">{{ metric | numberAbbr}}</div>
		<svg viewbox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
	      <circle class="circle-chart__background" stroke-width="4" fill="none" cx="50%" cy="50%" r="calc(50% - 4px)" />
	      <circle class="circle-chart__circle" stroke-width="4" stroke-linecap="round" fill="none" cx="50%" cy="50%" r="calc(50% - 4px)" :style="{'stroke-dasharray': strokeDash, stroke: color }" />
	    </svg>
	</div>
</template>

<script>
	export default {
		name: "CircleChart",
		props: [ 'metric', 'total', 'color' ],
		computed: {
			strokeDash() {
				const p = Math.round((this.metric / this.total) * 100) * 3;
				return `${p}% 300%`;
			}
		}
	}
</script>

<style lang="scss">
	@import '@/scss/colors';

	@keyframes circle-chart-fill {
		to { 
	  		stroke-dasharray: 0 300%; 
		}
	}
	
	.circle-chart {
		position: relative;
		width: 100%;
		padding-bottom: 100%;
		overflow: hidden;

		&-metric {
			position: absolute;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.25rem;
			font-weight: 600;
		}

		svg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			.circle-chart__background {
				stroke: $fade-gray;
			}

			.circle-chart__circle {
				animation-duration: 0.5s;
				animation-name: circle-chart-fill;
				animation-timing-function: linear;
				animation-direction: reverse;
				animation-iteration-count: 1;
				transform: rotate(-90deg);
				transform-origin: center;
				stroke:$light-blue;
				transition-timing-function: linear !important;
				transition:stroke 0.25s;
				-webkit-transition:stroke 0.25s, stroke-dasharray 0.5s;
			}
		}
	}
</style>